import React from 'react'


const Updates = () => {
  return (
    <>
      <div className="updates">
        <div className="updatesleft">
          <div className="innerup">
            Updates
          </div>
        </div>
        <div className="upinner">
          <p ><p>1. Admission will open for 2023</p></p>
          <p ><p>2. School Transport Arrangement</p></p>
        </div>
        {/* <div className="updatesright">
    
        </div> */}


      </div>
    </>
  )
}

export default Updates